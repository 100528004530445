import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { get } from "lodash";
import Swal from "sweetalert2";
import * as donationRepo from "../../../../../datasources/donationRepo";

export function ModalCreate({
  onHide,
  show,
  data,
  afterSubmit = () => {},
  file_type,
}) {
  const [formValue, setFormValue] = useState({});

  function changeValue(value, key) {
    setFormValue((prevState) => ({ ...prevState, [key]: value }));
  }

  async function submitData() {
    try {
      if (Object.keys(formValue).length > 0) {
        let formData = { ...formValue, file_type };
        await donationRepo.apiUploadCreate({ data: formData });
        Swal.fire("Data Created!", "Data Success Created!", "success");
        setFormValue({});
        afterSubmit();
        onHide();
      } else {
        Swal.fire("Error", `Please fill the form!`, "error");
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {data
            .filter((x) => !["salesforce_id"].includes(x))
            .map((value, i) => (
              <Col xs={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {value
                      .split("_")
                      .map((x) => x.charAt(0).toUpperCase() + x.substr(1))
                      .join(" ")}
                  </Form.Label>
                  <Form.Control
                    type="Text"
                    value={get(formValue, value, "")}
                    onChange={(e) => changeValue(e.target.value, value)}
                  />
                </Form.Group>
              </Col>
            ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submitData}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
