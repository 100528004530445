import {methodService, apiService} from './apiService';

export const URL = {
  BASE_DONATION: '/donations',
};

export const apiSendDonation = data => {
  return apiService(URL.BASE_DONATION, methodService.POST, data, null);
};

export const getStatusRedDot = data => {
  return apiService(
    URL.BASE_DONATION + `/${data.transactionId}/reddotpay-status`,
    methodService.GET,
    null,
    null,
  );
};

export function apiGetDetailUpload(id) {
  return apiService(URL.BASE_DONATION + `/detail/${id}`, methodService.GET);
}

export function apiUploadCsv(data) {
  return apiService(URL.BASE_DONATION + `/upload`, methodService.POST, data, null);
}

export function apiUploadCreate(data) {
  return apiService(URL.BASE_DONATION + `/upload/create`, methodService.POST, data, null);
}

export function apiUploadToSalesforce(data) {
  return apiService(URL.BASE_DONATION + `/uploadToSalesforce`, methodService.POST, data, null);
}

export function apiUploadList(data) {
  return apiService(URL.BASE_DONATION + `/upload`, methodService.GET, null, data);
}
export function apiDeleteUpload(id) {
  return apiService(URL.BASE_DONATION + `/upload/${id}`, methodService.DELETE);
}

export function apiDetailUpload(id) {
  return apiService(URL.BASE_DONATION + `/upload/${id}`, methodService.GET);
}

export function apiUpdateDonationUpload(data, id) {
  return apiService(URL.BASE_DONATION + `/upload/${id}`, methodService.PUT, data, null);
}
