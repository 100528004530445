import React from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {BiCoinStack} from 'react-icons/bi';
import {FaCloud, FaSignOutAlt} from 'react-icons/fa';
import './Navbar.css';

export function Header() {
  return (
    <>
      <Navbar bg="light" variant="light" className="container-navbar" style={{paddingLeft: '25px'}}>
        <Navbar.Brand className="ffth-logo" href="#home">
          <img
            src={'https://via.placeholder.com/308x100?text=example+logo'}
            style={{maxWidth: '150px'}}
          />
        </Navbar.Brand>
        <Nav className="mr-auto container-link container-link-upload d-flex">
          <Nav.Link className="link-navbar" href="/upload">
            <FaCloud style={{marginRight: '3px'}} />
            Importation Page
          </Nav.Link>
          <BiCoinStack style={{marginTop: '10px'}} />
          <NavDropdown style={{fontFamily: 'Helvetica Neue'}} title="Temporary Data">
            {/* <NavDropdown.Item href="/donation-list">
              Temporary Data - All
            </NavDropdown.Item> */}
            <NavDropdown.Item href="/donation-list#give_asia">
              Temporary Data - Give Asia
            </NavDropdown.Item>
            <NavDropdown.Item href="/donation-list#giving_sg">
              Temporary Data - Giving Sg
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link className="link-navbar" href="#features">
            <BiCoinStack style={{ marginRight: "3px" }} />
            Temporary Data
            <BiCaretDown />
          </Nav.Link> */}
          <Nav.Link className="link-navbar" href="#pricing">
            <FaSignOutAlt style={{marginRight: '3px'}} />
            Logout
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}
