import {lazy, Suspense, useRef} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import ReferralCS from './Modules/Referral/Pages/ReferralCS';
import Intake from './Modules/Referral/Pages/Intake';
import {UploadDonation} from './Modules/DonationUpload/pages/uploadDonation';
import {DonationListPage} from './Modules/DonationUpload/pages/donationList';
import {DonationDetailPage} from './Modules/DonationUpload/pages/DonationDetail';
const Donation = lazy(() => import('../src/Modules/Donation/Pages/Donation'));
const ThankYou = lazy(() => import('../src/Modules/Donation/Pages/ThankYou'));
const ReferralISS = lazy(() => import('../src/Modules/Referral/Pages/ReferralISS'));
const RegisterClient = lazy(() => import('../src/Modules/Referral/Pages/RegisterClient'));
const RegisterISS = lazy(() => import('../src/Modules/Referral/Pages/RegisterISS'));
const RegisterCS = lazy(() => import('../src/Modules/Referral/Pages/RegisterCS'));
const InformedConsent = lazy(() => import('../src/Modules/Referral/Pages/InformedConsent'));
const EnquiryCSPrev = lazy(() => import('./Modules/Enquiry/EnquiryCSPrev'));
const FinancialAssistance = lazy(() => import('./Modules/FinancialAssistance/index'));
const JobSupport = lazy(() => import('./Modules/JobSupport/index'));
const VolunteerRequest = lazy(() => import('../src/Modules/Volunteer/Pages/VolunteerRequest'));
const VolunteerProgram = lazy(() => import('../src/Modules/Volunteer/Pages/VolunteerProgram'));
const VolunteerTimesheet = lazy(() => import('../src/Modules/Volunteer/Pages/VolunteerTimesheet'));
const AttendanceProgram = lazy(() => import('../src/Modules/Volunteer/Pages/AttendanceProgram'));
const OrientationInvitation = lazy(() =>
  import('../src/Modules/Orientation/Pages/OrientationInvitation'),
);
const OrientationAttendances = lazy(() =>
  import('../src/Modules/Orientation/Pages/OrientationAttendances'),
);
const SignUp = lazy(() => import('../src/Modules/SignUp/Pages/SignUp'));
const SignupAccessPage = lazy(() => import('../src/Modules/SignUp/Pages/SignUpAccess'));
const SignupDetailPage = lazy(() => import('../src/Modules/SignUp/Pages/SignupDetailPage.js'));
const EnquiryISS = lazy(() => import('./Modules/Enquiry/EnquiryISS'));
const EnquiryCS = lazy(() => import('./Modules/Enquiry/EnquiryCS'));
const VerifyClient = lazy(() => import('../src/Modules/Verify/VerifyClient'));
const VerificationPage = lazy(() => import('../src/Modules/Verify/VerificationPage'));
const Error404 = lazy(() => import('../src/Modules/Error/404'));
const EndSubmission = lazy(() => import('../src/Modules/Referral/Pages/EndSubmission'));

function App() {
  const donationRoute = useRef(null);
  const volunteerRoute = useRef(null);
  const demoRoute = useRef(null);
  const csRoute = useRef(null);
  const issRoute = useRef(null);

  return (
    <>
      <Suspense fallback={<Spinner animation="border" variant="primary" className="m-auto " />}>
        {process.env.REACT_APP_BUILD_TYPE === 'donation' && (
          <Switch ref={donationRoute}>
            <Route path="/" exact component={Donation} />
            <Route path="/donate-result" component={ThankYou} />
            <Route component={Error404} />
          </Switch>
        )}

        {process.env.REACT_APP_BUILD_TYPE === 'volunteer' && (
          <Switch ref={volunteerRoute}>
            <Route path="/" exact component={SignUp} />
            <Route path="/sign-up-access" component={SignupAccessPage} />
            <Route path="/sign-up-detail" component={SignupDetailPage} />
            <Route path="/volunteer-request" component={VolunteerRequest} />
            <Route path="/volunteer-program" component={VolunteerProgram} />
            <Route path="/timesheet" component={VolunteerTimesheet} />
            <Route path="/attendance-program" component={AttendanceProgram} />
            <Route path="/orientation-invitation" component={OrientationInvitation} />
            <Route path="/orientation-attendances" component={OrientationAttendances} />
            <Route component={Error404} />
          </Switch>
        )}

        {process.env.REACT_APP_BUILD_TYPE === 'demo' && (
          <Switch ref={demoRoute}>
            <Route path="/referral-iss" component={ReferralISS} />
            <Route path="/referral-cs" component={ReferralCS} />
            <Route path="/register-client" component={RegisterClient} />
            <Route path="/register-iss" component={RegisterISS} />
            <Route path="/register-cs" component={RegisterCS} />
            <Route path="/enquiry-iss" component={EnquiryISS} />
            <Route path="/enquiry-iss-school" component={EnquiryISS} />
            <Route path="/enquiry-cs" component={EnquiryCS} />
            <Route path="/enquiry-cs-walk-in" component={EnquiryCS} />
            <Route path="/enquiry-cs-prev" component={EnquiryCSPrev} />
            <Route path="/intake-form" component={Intake} />
            <Route path="/verify-client" component={VerifyClient} />
            <Route path="/verification" component={VerificationPage} />
            <Route path="/financial-assistance" component={FinancialAssistance} />
            <Route path="/job-support" component={JobSupport} />
            <Route path="/informed-consent" component={InformedConsent} />
            <Route path="/referral-end" component={EndSubmission} />
            <Route path="/upload" component={UploadDonation} />
            <Route path="/donation-list/detail/:uploadId" component={DonationDetailPage} />
            <Route path="/donation-list" component={DonationListPage} />
            <Route component={Error404} />
          </Switch>
        )}

        {process.env.REACT_APP_BUILD_TYPE === 'iss' && (
          <Switch ref={issRoute}>
            <Route path="/" exact component={EnquiryISS} />
            <Route path="/referral-iss" component={ReferralISS} />
            <Route path="/register-client" component={RegisterClient} />
            <Route path="/register-iss" component={RegisterISS} />
            <Route path="/enquiry-iss-school" component={EnquiryISS} />
            <Route path="/intake-form" component={Intake} />
            <Route path="/verify-client" component={VerifyClient} />
            <Route path="/verification" component={VerificationPage} />
            <Route path="/referral-end" component={EndSubmission} />
            <Route component={Error404} />
          </Switch>
        )}

        {process.env.REACT_APP_BUILD_TYPE === 'cs' && (
          <Switch ref={csRoute}>
            <Route path="/" exact component={EnquiryCS} />
            <Route path="/referral-cs" component={ReferralCS} />
            <Route path="/register-client" component={RegisterClient} />
            <Route path="/register-cs" component={RegisterCS} />
            <Route path="/enquiry-cs-walk-in" component={EnquiryCS} />
            <Route path="/intake-form" component={Intake} />
            <Route path="/verify-client" component={VerifyClient} />
            <Route path="/verification" component={VerificationPage} />
            <Route path="/financial-assistance" component={FinancialAssistance} />
            <Route path="/job-support" component={JobSupport} />
            <Route path="/informed-consent" component={InformedConsent} />
            <Route path="/referral-end" component={EndSubmission} />
            <Route component={Error404} />
          </Switch>
        )}
      </Suspense>
    </>
  );
}

export default App;
